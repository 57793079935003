@import 'https://fonts.googleapis.com/css?family=Open+Sans:400,600,700';
/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
@font-face {
  font-family: wri-icons;
  font-style: normal;
  font-display: block;
  text-rendering: optimizelegibility;
  src: url("https://cdn.bfldr.com/NDQASMJ1/at/rpbvrmhhbmbrqhmwt58rw4t/wri-icons-2051.otf") format("opentype"), url("https://cdn.bfldr.com/NDQASMJ1/at/qxrt8b6gv3gc5nnwjsgmv5t/wri-icons-2051.woff") format("woff"), url("https://cdn.bfldr.com/NDQASMJ1/at/4n89j7fk58nfc8bqw5snfh/wri-icons-2051.ttf") format("truetype"), url("https://cdn.bfldr.com/NDQASMJ1/at/7tstb73kfqk3xr4t4b4f27fx/wri-icons-2051.svg") format("svg");
}
@font-face {
  font-family: "Roc Grotesk";
  src: url("https://cdn.bfldr.com/NDQASMJ1/at/r3b5k7qt9gc6w49jcrqvvx/RocGroteskCond-Medium.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Roc Grotesk";
  src: url("https://cdn.bfldr.com/NDQASMJ1/at/rmj7gvg8wkbrg8s87z2rszjc/RocGroteskCond-Bold.otf");
  font-weight: 600;
}
@font-face {
  font-family: "Roc Grotesk";
  src: url("https://cdn.bfldr.com/NDQASMJ1/at/rhfv6znz9ntcxs9vv3963j/RocGroteskCond-ExtraBold.otf");
  font-weight: 700;
}
/**
 * Do not edit directly
 * Generated on Mon, 24 Feb 2025 20:13:45 GMT
 */
/**
 * Do not edit directly
 * Generated on Mon, 24 Feb 2025 20:13:45 GMT
 */
:root {
  --wri-ink-base: #070707;
  --wri-ink-light: #4A4A4A;
  --wri-ink-lightest: #757575;
  --wri-ink-disabled: #A1A1A1;
  --wri-bg-dark: #6C6C6C;
  --wri-bg-base: #E0E0E0;
  --wri-bg-light: #F4F4F2;
  --wri-bg-lightest: #FFFFFF;
  --wri-bg-special-darker: #7E782E;
  --wri-bg-special-dark: #8F8946;
  --wri-bg-special-base: #C5C19B;
  --wri-bg-special-light: #E0E1CA;
  --wri-action-dark: #004324;
  --wri-action-base: #006938;
  --wri-spice-spicy: #D82525;
  --wri-spice-medium: #FD8809;
  --wri-spice-mild: #006938;
  --wri-elevation-low-up-x: 0;
  --wri-elevation-low-up-y: -2;
  --wri-elevation-low-up-blur: 10;
  --wri-elevation-low-up-spread: 0;
  --wri-elevation-low-up-color: rgba(0, 0, 0, 0.1490196078);
  --wri-elevation-low-up-type: dropShadow;
  --wri-elevation-low-down-x: 0;
  --wri-elevation-low-down-y: 2;
  --wri-elevation-low-down-blur: 10;
  --wri-elevation-low-down-spread: 0;
  --wri-elevation-low-down-color: rgba(0, 0, 0, 0.1490196078);
  --wri-elevation-low-down-type: dropShadow;
  --wri-elevation-high-up-x: 0;
  --wri-elevation-high-up-y: -5;
  --wri-elevation-high-up-blur: 24;
  --wri-elevation-high-up-spread: 0;
  --wri-elevation-high-up-color: rgba(0, 0, 0, 0.1607843137);
  --wri-elevation-high-up-type: dropShadow;
  --wri-elevation-high-down-x: 0;
  --wri-elevation-high-down-y: 10;
  --wri-elevation-high-down-blur: 24;
  --wri-elevation-high-down-spread: 0;
  --wri-elevation-high-down-color: rgba(0, 0, 0, 0.2392156863);
  --wri-elevation-high-down-type: dropShadow;
  --wri-error-base: #BB0000;
  --wri-error-light: #F8E6E6;
  --wri-gradient-redorange: conic-gradient(from 180deg at 50% 50%, #FD8809 -103.12deg, #AA3B2C 51.78deg, #FD8809 256.87deg, #AA3B2C 411.78deg);
  --wri-positive-light: #D1E8DA;
  --wri-positive-base: #178C48;
  --wri-warning-base: #FD8809;
  --wri-warning-light: #ffe7ce;
}
@media (prefers-color-scheme: light) {
  :root {
    --wri-ink-base: #070707;
    --wri-ink-light: #4A4A4A;
    --wri-ink-lightest: #757575;
    --wri-ink-disabled: #A1A1A1;
    --wri-bg-dark: #6C6C6C;
    --wri-bg-base: #E0E0E0;
    --wri-bg-light: #F4F4F2;
    --wri-bg-lightest: #FFFFFF;
    --wri-bg-special-darker: #7E782E;
    --wri-bg-special-dark: #8F8946;
    --wri-bg-special-base: #C5C19B;
    --wri-bg-special-light: #E0E1CA;
    --wri-action-dark: #004324;
    --wri-action-base: #006938;
    --wri-spice-spicy: #D82525;
    --wri-spice-medium: #FD8809;
    --wri-spice-mild: #006938;
    --wri-elevation-low-up-x: 0;
    --wri-elevation-low-up-y: -2;
    --wri-elevation-low-up-blur: 10;
    --wri-elevation-low-up-spread: 0;
    --wri-elevation-low-up-color: rgba(0, 0, 0, 0.1490196078);
    --wri-elevation-low-up-type: dropShadow;
    --wri-elevation-low-down-x: 0;
    --wri-elevation-low-down-y: 2;
    --wri-elevation-low-down-blur: 10;
    --wri-elevation-low-down-spread: 0;
    --wri-elevation-low-down-color: rgba(0, 0, 0, 0.1490196078);
    --wri-elevation-low-down-type: dropShadow;
    --wri-elevation-high-up-x: 0;
    --wri-elevation-high-up-y: -5;
    --wri-elevation-high-up-blur: 24;
    --wri-elevation-high-up-spread: 0;
    --wri-elevation-high-up-color: rgba(0, 0, 0, 0.1607843137);
    --wri-elevation-high-up-type: dropShadow;
    --wri-elevation-high-down-x: 0;
    --wri-elevation-high-down-y: 10;
    --wri-elevation-high-down-blur: 24;
    --wri-elevation-high-down-spread: 0;
    --wri-elevation-high-down-color: rgba(0, 0, 0, 0.2392156863);
    --wri-elevation-high-down-type: dropShadow;
    --wri-error-base: #BB0000;
    --wri-error-light: #F8E6E6;
    --wri-gradient-redorange: conic-gradient(from 180deg at 50% 50%, #FD8809 -103.12deg, #AA3B2C 51.78deg, #FD8809 256.87deg, #AA3B2C 411.78deg);
    --wri-positive-light: #D1E8DA;
    --wri-positive-base: #178C48;
    --wri-warning-base: #FD8809;
    --wri-warning-light: #ffe7ce;
  }
}
@media (prefers-color-scheme: dark) {
  :root {
    --wri-ink-base: #FFFFFF;
    --wri-ink-light: #4A4A4A;
    --wri-ink-lightest: #757575;
    --wri-bg-dark: #6C6C6C;
    --wri-bg-base: #E0E0E0;
    --wri-bg-light: #F4F4F2;
    --wri-bg-lightest: #FFFFFF;
    --wri-bg-special-dark: #8F8946;
    --wri-bg-special-base: #C5C19B;
    --wri-bg-special-light: #E0E1CA;
    --wri-action-dark: #06341B;
    --wri-action-base: #0b582b;
    --wri-spice-spicy: #D82525;
    --wri-spice-medium: #FD8809;
    --wri-spice-mild: #0B582B;
    --wri-elevation-low-up-x: 0;
    --wri-elevation-low-up-y: -2;
    --wri-elevation-low-up-blur: 10;
    --wri-elevation-low-up-spread: 0;
    --wri-elevation-low-up-color: rgba(0, 0, 0, 0.1490196078);
    --wri-elevation-low-up-type: dropShadow;
    --wri-elevation-low-down-x: 0;
    --wri-elevation-low-down-y: 2;
    --wri-elevation-low-down-blur: 10;
    --wri-elevation-low-down-spread: 0;
    --wri-elevation-low-down-color: rgba(0, 0, 0, 0.1490196078);
    --wri-elevation-low-down-type: dropShadow;
    --wri-elevation-high-up-x: 0;
    --wri-elevation-high-up-y: -5;
    --wri-elevation-high-up-blur: 24;
    --wri-elevation-high-up-spread: 0;
    --wri-elevation-high-up-color: rgba(0, 0, 0, 0.1607843137);
    --wri-elevation-high-up-type: dropShadow;
    --wri-elevation-high-down-x: 0;
    --wri-elevation-high-down-y: 10;
    --wri-elevation-high-down-blur: 24;
    --wri-elevation-high-down-spread: 0;
    --wri-elevation-high-down-color: rgba(0, 0, 0, 0.2392156863);
    --wri-elevation-high-down-type: dropShadow;
    --wri-error-base: #BB0000;
    --wri-error-light: #F8E6E6;
    --wri-warning-base: #FD8809;
    --wri-warning-light: #ffe7ce;
  }
}

/* stylelint scss/dollar-variable-pattern: /foo-.+/ */
div {
  overflow-wrap: anywhere;
}

.flex-align-left {
  margin-left: auto;
}

.menu-link,
.at {
  overflow-wrap: normal;
}

.align-center {
  text-align: center;
}

.focus-outline {
  outline: none;
}
.focus-outline:focus {
  outline: none !important;
}
.focus-outline:focus-visible {
  outline: 1px solid #006938 !important;
  outline-offset: 2px !important;
}

.tabfocus:focus .cross-sell-card {
  outline: 1px solid #006938 !important;
}

a:hover,
button:hover {
  cursor: pointer;
}

.tabfocus:hover {
  color: #006938;
  text-decoration: none;
}
.tabfocus:hover .cross-sell-card {
  outline: 2px solid #006938 !important;
}
.tabfocus:hover .flavor-card {
  color: #070707;
}

.toast-error {
  background-color: #F8E6E6 !important;
  color: #BB0000 !important;
  top: 32px;
  width: 75vw !important;
  background-image: url("../assets/error-info.svg") !important;
  display: grid;
  grid-template-columns: 1fr 32px;
  grid-template-areas: "msg cls";
  align-items: center;
  text-overflow: ellipsis;
}

.toast-success {
  background-color: #D1E8DA !important;
  color: #4A4A4A !important;
  top: 32px;
  width: 75vw !important;
  background-image: url("../assets/success-check.svg") !important;
  display: grid;
  grid-template-columns: 1fr 32px;
  grid-template-areas: "msg cls";
  align-items: center;
  text-overflow: ellipsis;
}

.toast-warning {
  background-color: #ffe7ce !important;
  color: #4A4A4A !important;
  top: 32px;
  width: 75vw !important;
  background-image: url("../assets/warning.svg") !important;
  display: grid;
  grid-template-columns: 1fr 32px;
  grid-template-areas: "msg cls";
  align-items: center;
  text-overflow: ellipsis;
}

.toast-message {
  grid-area: msg;
}

.toast-close-button {
  margin-left: auto !important;
  background-repeat: no-repeat !important;
  background-image: url("../assets/close.svg") !important;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  width: 32px;
  top: 8px !important;
  grid-area: cls;
}

.toast-container .ngx-toastr,
toast-container .ngx-toastr:hover {
  box-shadow: none !important;
}
@media (prefers-color-scheme: light) {
  .toast-container .ngx-toastr,
  toast-container .ngx-toastr:hover {
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1490196078);
  }
}
@media (prefers-color-scheme: dark) {
  .toast-container .ngx-toastr,
  toast-container .ngx-toastr:hover {
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1490196078);
  }
}

.modal-backdrop {
  background-color: #070707 !important;
}

.wri-btn {
  font-family: Roc Grotesk, Open Sans, sans-serif;
  font-size: 22px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  border-radius: 4px;
  border: none;
  text-wrap: nowrap;
  padding: 10px 24px 8px 24px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.wri-btn-primary {
  background: #006938;
  color: #FFFFFF;
  width: 100%;
}
.wri-btn-primary:focus-visible {
  outline: 1px solid #006938;
  outline-offset: 2px;
}
.wri-btn-primary:hover, .wri-btn-primary:active, .wri-btn-primary:focus {
  color: #FFFFFF;
  background-color: #004324;
}
.wri-btn-primary:disabled, .wri-btn-primary[disabled=""] {
  background: #A1A1A1;
  pointer-events: none;
}
.wri-btn-secondary {
  background: #FFFFFF;
  color: #006938;
  border: 1px solid #006938;
  width: 100%;
}
.wri-btn-secondary:focus-visible {
  outline: 1px solid #006938;
  outline-offset: 2px;
}
.wri-btn-secondary:hover, .wri-btn-secondary:active, .wri-btn-secondary:focus {
  border: 1px solid #004324;
  color: #004324;
  background-color: rgba(0, 67, 36, 0.1019607843);
}
.wri-btn-secondary:disabled, .wri-btn-secondary[disabled=""] {
  border: 1px solid #A1A1A1;
  background: #FFFFFF;
  color: #A1A1A1;
  pointer-events: none;
}
.wri-btn-tertiary {
  background: #FFFFFF;
  color: #4A4A4A;
  border: 1px solid #4A4A4A;
  width: 100%;
}
.wri-btn-tertiary:focus-visible {
  outline: 1px solid #006938;
  outline-offset: 2px;
}
.wri-btn-tertiary:hover, .wri-btn-tertiary:active, .wri-btn-tertiary:focus {
  border: 1px solid #070707;
  color: #070707;
  background-color: rgba(7, 7, 7, 0.1019607843);
}
.wri-btn-tertiary:disabled, .wri-btn-tertiary[disabled=""] {
  border: 1px solid #A1A1A1;
  background: #FFFFFF;
  color: #A1A1A1;
  pointer-events: none;
}
.wri-btn-text {
  font-family: Roc Grotesk, Open Sans, sans-serif;
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.4px;
  color: #006938;
  background-color: transparent;
  width: 100%;
  padding: 0;
}
.wri-btn-text:focus-visible {
  outline: 1px solid #006938;
  outline-offset: 2px;
}
.wri-btn-text:hover, .wri-btn-text:active, .wri-btn-text:focus {
  color: #004324;
  text-decoration: none;
}
.wri-btn-text:disabled, .wri-btn-text[disabled=""] {
  color: #A1A1A1;
}
.wri-btn-secondary-error {
  background: #FFFFFF;
  color: #BB0000;
  border: 1px solid #BB0000;
  width: 100%;
}
.wri-btn-secondary-error:focus-visible {
  outline: 1px solid #006938;
  outline-offset: 2px;
}
.wri-btn-secondary-error:hover, .wri-btn-secondary-error:active, .wri-btn-secondary-error:focus {
  background-color: rgba(187, 0, 0, 0.1019607843);
}
.wri-btn-secondary-error:disabled, .wri-btn-secondary-error[disabled=""] {
  border: 1px solid #A1A1A1;
  background: #FFFFFF;
  color: #A1A1A1;
  pointer-events: none;
}
.wri-btn-primary-icon {
  justify-content: center;
  align-items: center;
  display: flex;
  background: #006938;
  color: #FFFFFF;
}
.wri-btn-primary-icon:focus-visible {
  outline: 1px solid #006938;
  outline-offset: 2px;
}
.wri-btn-primary-icon:hover, .wri-btn-primary-icon:active, .wri-btn-primary-icon:focus {
  color: #FFFFFF;
  background-color: #004324;
}
.wri-btn-primary-icon:disabled, .wri-btn-primary-icon[disabled=""] {
  background: #A1A1A1;
  pointer-events: none;
}
.wri-btn-secondary-icon {
  justify-content: center;
  align-items: center;
  display: flex;
  background: #FFFFFF;
  color: #006938;
  border: 1px solid #006938;
}
.wri-btn-secondary-icon:focus-visible {
  outline: 1px solid #006938;
  outline-offset: 2px;
}
.wri-btn-secondary-icon:hover, .wri-btn-secondary-icon:active, .wri-btn-secondary-icon:focus {
  border: 1px solid #004324;
  color: #004324;
  background-color: rgba(0, 67, 36, 0.1019607843);
}
.wri-btn-secondary-icon:disabled, .wri-btn-secondary-icon[disabled=""] {
  border: 1px solid #A1A1A1;
  background: #FFFFFF;
  color: #A1A1A1;
  pointer-events: none;
}
.wri-btn-tertiary-icon {
  justify-content: center;
  align-items: center;
  display: flex;
  background: #FFFFFF;
  color: #4A4A4A;
  border: 1px solid #4A4A4A;
}
.wri-btn-tertiary-icon:focus-visible {
  outline: 1px solid #006938;
  outline-offset: 2px;
}
.wri-btn-tertiary-icon:hover, .wri-btn-tertiary-icon:active, .wri-btn-tertiary-icon:focus {
  border: 1px solid #070707;
  color: #070707;
  background-color: rgba(7, 7, 7, 0.1019607843);
}
.wri-btn-tertiary-icon:disabled, .wri-btn-tertiary-icon[disabled=""] {
  border: 1px solid #A1A1A1;
  background: #FFFFFF;
  color: #A1A1A1;
  pointer-events: none;
}
.wri-btn-text-icon {
  font-family: Roc Grotesk, Open Sans, sans-serif;
  font-size: 22px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.4px;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #006938;
  background-color: transparent;
  padding: 0;
}
.wri-btn-text-icon:focus-visible {
  outline: 1px solid #006938;
  outline-offset: 2px;
}
.wri-btn-text-icon:hover, .wri-btn-text-icon:active, .wri-btn-text-icon:focus {
  color: #004324;
  text-decoration: none;
}
.wri-btn-text-icon:disabled, .wri-btn-text-icon[disabled=""] {
  color: #A1A1A1;
}

.sticky-footer .wri-btn-primary-icon:hover {
  background-color: #006938;
  cursor: pointer;
}

.wri-modal__wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.wri-modal__title {
  font-family: Roc Grotesk, Open Sans, sans-serif;
  font-size: 48px;
  line-height: 48px;
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
}
.wri-modal__description {
  font-family: Open Sans, Open Sans, sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-transform: none;
  text-decoration: none;
  text-align: center;
  color: #070707;
}
.wri-modal__btn-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.common-modal .modal-sm {
  max-width: 375px;
}
.common-modal .modal-md {
  max-width: 700px;
}
.common-modal .modal-lg {
  max-width: 1190px;
}
.common-modal .modal-content {
  padding: 40px 24px;
  border-radius: 0;
}
.common-modal .info-icon {
  background: none;
  border: none;
  color: #006938;
  display: inline-flex;
  padding: 0;
  font-size: 16px;
  text-align: left;
}
.common-modal .info-icon:hover {
  color: #004324;
  cursor: pointer;
}

.round-up-modal .modal-content {
  padding: 40px 24px;
}

body.modal-open .modal {
  background: rgba(7, 7, 7, 0.5);
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.wri-divider {
  border: 0;
  border-top: 1px solid #E0E0E0;
  margin: 16px 0;
}

.arrow-right-icon {
  transform: rotate(180deg);
}

.flex {
  display: flex;
}

.column-flex {
  flex-direction: column;
}

.menu-item-background {
  position: relative;
  overflow: hidden;
  background: linear-gradient(0deg, #F4F4F2 3.25%, transparent 97.31%);
}
.menu-item-background::before {
  content: "";
  position: absolute;
  width: 200vw;
  height: 200vh;
  top: -50vh;
  left: -50vw;
  z-index: -1;
  background-image: url("../assets/menu-bg-banner.svg");
  background-repeat: repeat;
  transform: rotate(-15deg);
}
.menu-item-background::after {
  content: "";
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -2;
  background-color: #F4F4F2;
}

.p-left-15 {
  padding-left: 15px;
}

.m-left-15 {
  margin-left: 15px;
}

.slick-prev.slick-arrow {
  left: 0px !important;
  border: none;
  background: rgba(255, 255, 255, 0.6) 50% 50% no-repeat url("/assets/icons/black-arrow-left-small.svg");
}
@media only screen and (max-width: 991px) {
  .slick-prev.slick-arrow {
    left: 12px !important;
  }
}
.slick-prev.slick-arrow:hover {
  border: none;
  background: rgba(11, 88, 43, 0.8) 50% 50% no-repeat url("/assets/icons/white-arrow-left-small.svg");
}

.slick-next.slick-arrow {
  right: 0px !important;
  border: none;
  background: rgba(255, 255, 255, 0.6) 50% 50% no-repeat url("/assets/icons/black-arrow-right-small.svg");
}
@media only screen and (max-width: 991px) {
  .slick-next.slick-arrow {
    right: 12px !important;
  }
}
.slick-next.slick-arrow:hover {
  border: none;
  background: rgba(11, 88, 43, 0.8) 50% 50% no-repeat url("/assets/icons/white-arrow-right-small.svg");
}

.slick-disabled {
  display: none !important;
  pointer-events: none;
}

.slick-track {
  display: flex !important;
}

.slick-dots li {
  background-color: #E0E0E0;
  width: 18px;
  height: 8px;
  border-radius: 5px;
}
.slick-dots li button:focus {
  outline: none !important;
}
.slick-dots li button::before {
  top: -5px;
  color: transparent;
}
.slick-dots li.slick-active {
  background-color: #006938;
  width: 33px;
}
.slick-dots li.slick-active button::before {
  color: transparent;
}

wri-modifier-modal .toast-container.inline,
wri-choose-flavor-modal .toast-container.inline {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 0 12px;
  height: auto;
}
wri-modifier-modal .toast-container.inline .ngx-toastr,
wri-choose-flavor-modal .toast-container.inline .ngx-toastr {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15) !important;
  border-radius: 4px;
  top: 0;
}
wri-modifier-modal .inline-toast,
wri-choose-flavor-modal .inline-toast {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto;
  height: auto;
}

.remove-payment-method-modal .modal-dialog,
.remove-favorite-location-modal .modal-dialog,
.remove-my-address-modal .modal-dialog {
  max-width: 310px;
}

.active-offer-modal .modal-dialog {
  max-width: 488px;
}

.loyalty-modal .modal-dialog {
  max-width: 358px;
}

.full-width {
  width: 100%;
}